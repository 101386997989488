<template>
  <div class="row h-100 mb-10 justify-content-center">

    <div class="col-lg-10 col-xl-10">
      <div class="d-flex flex-column px-7 mt-10">
        <div class="row align-items-center">
          <div class="col-12 d-flex justify-content-start">
            <div class="d-flex flex-column">
                <h2 v-if="isBandoZac" class="card-label h1 font-weight-bolder">Scenari di
                  sviluppo</h2>
                <h2 v-else class="card-label h1 font-weight-bolder">Contesto</h2>
            </div>
          </div>
        </div>
         <!--::search-->
        <div class="row mb-5">
          <div class="col-xl-12">
            <form method="get" class="quick-search-form">
              <div class="input-group bg-tranparent border-0">
                <div class="input-group-prepend bg-transparent">
                  <button
                    class="input-group-text line-height-0 py-0 bg-transparent border-0"
                    v-on:click="getSearchData($event)"
                  >
                    <img
                      src="/assets/media/bipart/ico-search.png"
                      alt=""
                      class="w-100"
                    />
                  </button>
                </div>
                <input
                  type="text"
                  class="form-control py-10 h4 bg-transparent border-0 text-dark-50"
                  placeholder="Search..."
                  v-model="keywords"
                />
                <div class="input-group-append">
                  <span
                    class="input-group-text h3 px-10 text-primary font-weight-bold bg-transparent border-0"
                  >
                    {{ scenariosCount }}
<!--                    <span class="text-muted h6 ml-4">scenari</span>-->
                  </span>
                </div>
              </div>
            </form>
          </div>
        </div>
        <!--::Module Filter -->
        <!--::End Module Filter -->
        <!--begin::Elenco processi row-1-->
        <div class="row w-100">
          <div
            id="my-cards"
            class="col-xl-4 gutter-b"
            v-for="(item, index) in scenarios"
            :key="item.id"
          >
            <ScenarioCard :scenario="item" :index="index"></ScenarioCard>
          </div>
        </div>
        <!--begin::Pagination-->
        <Pagination
        v-if="pageCount > 1"
        :current-page="currentPage"
        :page-count="pageCount"
        :item-count="scenariosCount"
        :per-page="$options.static.visibleItemsPerPageCount"
        :visiblePagesCount="Math.ceil(scenariosCount/$options.static.visibleItemsPerPageCount)"
        class="scenario-list-pagination"
        @firstPage="pageChangeHandle('first')"
        @nextPage="pageChangeHandle('next')"
        @previousPage="pageChangeHandle('previous')"
        @lastPage="pageChangeHandle('last')"
        @loadPage="pageChangeHandle"
      ></Pagination>
        <!--end::Pagination -->
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Pagination from "@/view/layout/common/Pagination";
import ScenarioCard from "./partials/ScenarioCard";
//import OrganizationMenu from "@/view/layout/common/OrganizationMenu.vue";

export default {
  name: "ScenarioList",
  components: {
    Pagination,
    ScenarioCard,
    //OrganizationMenu
  },
  static: {
    visibleItemsPerPageCount: 6
  },
  data() {
    return {
      scenarios: [], //[{ id: 1 }, { id: 2 }, { id: 3 }],
      scenariosCount: null, //3,
      currentPage: 1,
      pageCount: 0,
      keywords: "",
    };
  },
  computed: {
    isBandoZac() {
      return window.location.host.includes('bandozac.bipart.it');
    },
  },
  mounted() {
    // set the tab from previous
    // this.setActivePreviousTab();

    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.isBandoZac ? "Scenari di sviluppo" : this.$t("MAINMENU.LISTSCENARIOS") }]);

    this.getData();
  },
  created() {},
  methods: {
    getSearchData(event) {
      event.preventDefault();
      this.getData();
    },
    getData() {
      this.$isLoading(true);
      return new Promise((resolve, reject) => {

        let qs = "size=" + this.$options.static.visibleItemsPerPageCount + "&page=" + this.currentPage;

        if (this.keywords) qs += "&title.contains=" + this.keywords;

        var endpoint = this.generateUrl("scenarios-expanded", true);
        //generateUrl is done
        ApiService.get(this.$apiResource, endpoint + "?" + qs)
          .then(res => {
            // console.log(res);
            this.scenarios = res.data;
            this.scenariosCount = res.data.length;
            this.$isLoading(false);
            this.pageCount = res.data.meta.pages;
          })
            .catch(() => {
          // .catch(({ response }) => {
          //   console.log(response);
            // context.commit(SET_ERROR, [response.data.error]);
            reject(0);
            this.$isLoading(false);
          });
      });
    },
    async pageChangeHandle(value) {
      switch (value) {
        case "next":
          this.currentPage += 1;
          break;
        case "previous":
          this.currentPage -= 1;
          break;
        case "first":
          this.currentPage = 1;
          break;
        case "last":
          this.currentPage = this.pageCount;
          break;
        default:
          this.currentPage = value;
      }
      await this.getData();
    }
  }
};
</script>

<style scoped></style>
